import React, {useState, useEffect} from 'react';
import api from "../apiClient";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";

const SimulatorAssignee = ({assignee, deviceId, className}) => {

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(false);
  }, [assignee]);

  const addAssignee = async (deviceId) => {
    setIsLoading(true);
    const resp = await api.addSimulatorAssignee(deviceId)
    if (resp.error) {
      setIsLoading(false);
      alert(resp.error);
    }
  }

  const removeAssignee = async (deviceId) => {
    setIsLoading(true);
    await api.removeSimulatorAssignee(deviceId)
  }

  const renderAssigned = (deviceId, assignee) => {
    return (
        <div>
          Assigned to <span className="bold"> {assignee} </span>
          <IconButton color='secondary' onClick={() => removeAssignee(deviceId)}>
            <DeleteIcon/>
          </IconButton>
        </div>
    );
  }

  const renderUnassigned = () => {
    return (
        <div>
            No assignee&nbsp;
            <Button variant="contained"
                    color="primary"
                    disabled={assignee}
                    onClick={() => addAssignee(deviceId)}>
              Assign to me
            </Button>
        </div>
    );
  }

  if (isLoading) {
    return (
        <div>
          <CircularProgress color="secondary"/>
        </div>
    )
  }

  return (
      <div className={className}>
        <div className="bold">Assignee</div>
        {assignee
            ? renderAssigned(deviceId, assignee)
            : renderUnassigned()}
      </div>
  );
}

export default SimulatorAssignee;
