import React from "react";
import moment from "moment-timezone";
import PhoneIcon from '@material-ui/icons/PhoneAndroidTwoTone';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FiberPinIcon from '@material-ui/icons/FiberPinOutlined';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import {useQuery} from "react-query";
import api from "../apiClient";

const CalendarEvents = (props) => {
  const calendarIds = props.calendarIds || [];

  const from = moment().format('YYYY-MM-DD');
  const to = moment().format('YYYY-MM-DD');
  const calendarQuery = useQuery(['calendar', from, to], async () => {
    const calendarData = await api.getCalendar(null, from, to);
    if (calendarData.error) {
      throw new Error();
    }
    return calendarData.data.events;
  })
  const calendarEvents = calendarQuery.data;

  if (!calendarEvents) {
    return null;
  }
  const events = calendarEvents.filter(event => calendarIds.includes(event.locationId));
  return (
      <div className="top-margin">
        <strong>Today</strong><br/>
        {renderEvents(events)}
      </div>
  )
}

const renderEvents = events => {
  if (events.length === 0) {
    return <div>No events</div>
  }
  return events.map(event => (
      <Accordion key={event.eventId}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            id={event.eventId}
        >
          <Typography>
            <strong>{moment(event.startTime).format('HH:mm')}</strong> {event.firstName} {event.lastName}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="calendar-event-details">
            <div className="h-align-center">
              <PhoneIcon className="margin-right"/> {event.phone}
            </div>
            <div className="h-align-center">
              <DescriptionIcon className="margin-right"/> {event.lang} {event.topic}
            </div>
            <div className="h-align-center">
              <FiberPinIcon className="margin-right"/> {event.pincode}
            </div>
            <div className="small-text">
              Finnosims:
              <div className="small-text">
                Student id: {event.studentId}
              </div>
              <div className="small-text">
                Event id: {event.eventId}
              </div>
              <div className="small-text">
                Location id: {event.locationId}
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
  ));
}

export default CalendarEvents;
