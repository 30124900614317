import React, {useState} from 'react';
import api from "../apiClient";
import Button from "@material-ui/core/Button";
import WarningTwoToneIcon from "@material-ui/icons/WarningTwoTone";

const DoorUnlock = ({deviceId, className}) => {

  const [isUnlockButtonVisible, setIsUnlockButtonVisible] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);

  const sendUnlockCommand = async (deviceId) => {
    setIsRequesting(true);
    setResult(null);
    const resp = await api.unlockDoor(deviceId);
    setError(null);
    if (resp.error) {
      setError(resp.error);
    } else {
      setResult('Unlock requested...');
    }
    setIsRequesting(false);
  }

  if (!isUnlockButtonVisible) {
    return (
        <div className={className}>
          <Button variant="contained" onClick={() => setIsUnlockButtonVisible(true)}>Unlock Door</Button>
        </div>
    )
  }

  return (
      <div className={className}>
        <div>Warning, this will unlock the door and anyone can access the premises!</div>
        <Button variant="contained" color="secondary" onClick={() => sendUnlockCommand(deviceId)} disabled={isRequesting || result}>
          <WarningTwoToneIcon/> <strong>Unlock</strong> <WarningTwoToneIcon/>
        </Button>
        {error ? <div>{error}</div> : null}
        {result ? <div>{result}</div> : null}
      </div>
  );
}

export default DoorUnlock;
