import React from 'react';
import Button from "@material-ui/core/Button";
import MeetingRoomTwoToneIcon from '@material-ui/icons/MeetingRoomTwoTone';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import CloseIcon from "@material-ui/icons/Close";
import DoorUnlock from "./DoorUnlock";
import CalendarEvents from "./CalendarEvents";
import DoorGetManualPinCodes from "./DoorGetManualPinCodes";
import DoorGetLogs from "./DoorGetLogs";
import DoorControllerRestart from "./DoorControllerRestart";
import DoorSimulatorsRestart from "./DoorSimulatorsRestart";


const Door = ({data, clearSelectionCallback}) => {
  const {status} = data;
  return (
      <div className="gray-container">
        <div className="h-align-sides">
          <div className="h-align-center larger">
            <MeetingRoomTwoToneIcon/> {data.status.locationName}
          </div>
          <Button onClick={() => clearSelectionCallback()}>
            <CloseIcon/>
          </Button>
        </div>
        <div className="device-container">
          <div className="device-tools-container">
            <DoorUnlock deviceId={data.status.deviceId} className={"device-section-container"}/>
            <DoorGetManualPinCodes deviceId={data.status.deviceId} className="device-section-container"/>
            <CalendarEvents calendarIds={data.status.calendarIds}/>
            <DoorControllerRestart deviceId={data.status.deviceId} className={"device-section-container"}/>
            <DoorSimulatorsRestart deviceId={data.status.deviceId} className={"device-section-container"}/>
          </div>
          <div className="device-status-container">
            <div className="full-width">
              {data.isOffline
                  ?
                  <span className="h-align-center"><WifiOffIcon/> <span className='bold'>Door is offline.</span></span>
                  : null}
              <div className="top-margin lesson-current">
                {Object.entries(status).map(([key, value], i) => <div key={i}><span
                    className="bold">{key}:</span> {value}
                </div>)}
              </div>
              <DoorGetLogs deviceId={data.status.deviceId} className="device-section-container"/>
            </div>
          </div>
        </div>
      </div>
  );
}

export default Door;
