import React, {useEffect, useState} from "react";
import DevicesList from "./DevicesList";
import Simulator from "./Simulator";
import api from "../apiClient";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import CalendarOverview from "./CalendarOverview";
import simdashLogo from '../assets/simdash_logo.png';
import SimulatorAlertBeeper from "./SimulatorAlertBeeper";
import SimulatorNotesOverview from "./SimulatorNotesOverview";
import moment from "moment-timezone";
import Door from "./Door";
import Profile from "./Profile";
import {Line} from 'rc-progress';
import SimulatorCallRequestBeeper from "./SimulatorCallRequestBeeper";
import DoorAlertBeeper from "./DoorAlertBeeper";


const RELOAD_INTERVAL_SECS = 10;
const INFO_END_DRIVE = 'End drive';
const OFFLINE_PING_LIMIT_SECS = 100;

const DashboardMain = () => {

  const [allSimulators, setSimulators] = useState([]);
  const [simulatorError, setSimulatorError] = useState(null);
  const [doorError, setDoorError] = useState(null);
  const [allDoors, setDoors] = useState([]);
  const [reloadInSecs, setReloadInSecs] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [selection, setSelection] = useState(null)
  const [tick, setTick] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const [alertSoundsEnabled, setAlertSoundsEnabled] = useState(false);
  const [callSoundsEnabled, setCallSoundsEnabled] = useState(false);

  const locationSort = ((a, b) => {
    if (!a.status) {
      return -1;
    }
    if (!b.status) {
      return 1;
    }
    const locationA = a.status.locationName || '';
    const locationB = b.status.locationName || '';
    return locationA.localeCompare(locationB);
  });

  useEffect(() => {
    if (reloadInSecs <= 0) {
      setLoading(true);
      const promises = [];
      setSimulatorError(null);
      setDoorError(null);
      promises.push(api.getSimulators()
          .then(simulators => {
            if (simulators.error) {
              setSimulatorError(simulators.error);
            } else {
              simulators.sort(locationSort);
              simulators.forEach(simulator => {
                simulator.deviceType = 'simulator'; // Server could set device type
                const status = simulator.status;
                if (!status) {
                  simulator.isActive = false;
                } else {
                  simulator.isActive = !!simulator.status.lesson && simulator.status.info && !simulator.status.info.includes(INFO_END_DRIVE);
                }

                simulator.isOffline = simulator.ping && moment().diff(simulator.ping, 'seconds') > OFFLINE_PING_LIMIT_SECS;
              });
              setSimulators(simulators);
            }
            // TODO Error handling
          })
          .catch(e => {
            setSimulatorError('Simulator api error: ' + e.toString());
          })
      );

      promises.push(api.getDoors()
          .then(doors => {
            if (doors.error) {
              setDoorError(doors.error);
            } else {
              doors.sort((a, b) => a.status.locationName.localeCompare(b.status.locationName));
              doors.forEach(door => {
                door.deviceType = 'door';
                door.isOffline = door.ping && moment().diff(door.ping, 'seconds') > OFFLINE_PING_LIMIT_SECS;
                door.uid = [door.deviceType, door.deviceId].join('-');
              });
              setDoors(doors);
            }
            // TODO Error handling
          })
          .catch(e => {
            setDoorError('Door api error: ' + e.toString);
          })
      );

      // TODO Maybe add API endpoint for getting all device types in one call?

      Promise.all(promises).then(() => {
        setLoading(false);
        setReloadInSecs(RELOAD_INTERVAL_SECS);
      });
    }
  }, [reloadInSecs])

  useEffect(() => {
    if (!isLoading) {
      setReloadInSecs(s => s - 1);
    }
  }, [tick, isLoading]);

  useEffect(() => {
    const tickInterval = setInterval(() => setTick(t => t + 1), 1000);
    return () => {
      clearInterval(tickInterval);
    }
  }, [])

  const selectCallback = (deviceType, deviceId) => {
    if (selection && selection.deviceType === deviceType && selection.deviceId === deviceId) {
      setSelection(null);
    } else {
      setSelection({deviceType, deviceId});
    }
  }

  const clearSelectionCallback = () => {
    setSelection(null);
  }

  let selectedDevice = null;
  if (selection) {
    if (selection.deviceType === 'simulator') {
      const selectedSimulator = allSimulators.find(simulator => simulator.deviceId === selection.deviceId)
      selectedDevice = <Simulator key={selectedSimulator._id} data={selectedSimulator} calendarEventId={null}
                                  clearSelectionCallback={clearSelectionCallback}/> // TODO Simulator component data contains the calendar event id, right?
    } else if (selection.deviceType === 'door') {
      const selectedDoor = allDoors.find(door => door.deviceId === selection.deviceId);
      selectedDevice =
          <Door key={selectedDoor._id} data={selectedDoor} clearSelectionCallback={clearSelectionCallback}/>
    }
  }

  return (
      <div>
        <div className='top-container'>
          <div className="mainmenu">
            <div className='logo-container'>
              <img src={simdashLogo} className="App-logo" alt="logo"/>
            </div>
            <Profile/>
            <div className="top-margin">
              {isLoading
                  ? <Line className="reload-bar" percent={100} strokeWidth={5} trailWidth={6} strokeColor="#f8a30011"
                          trailColor="#00000022"/>
                  : <Line className="reload-bar" percent={(reloadInSecs / RELOAD_INTERVAL_SECS) * 100} strokeWidth={5}
                          trailWidth={6} strokeColor="#00000055" trailColor="#00000022"/>
              }
            </div>
            <div>
              <FormControlLabel
                  control={
                    <Switch
                        checked={showAll}
                        onChange={e => setShowAll(e.target.checked)}
                        color="primary"
                    />
                  }
                  label="Show all"
              />
            </div>
            <div>
              <FormControlLabel
                  control={
                    <Switch
                        checked={alertSoundsEnabled}
                        onChange={e => {
                          setAlertSoundsEnabled(e.target.checked);
                        }}
                        color="primary"
                    />
                  }
                  label="Alert sounds"
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={callSoundsEnabled}
                    onChange={e => {
                      setCallSoundsEnabled(e.target.checked);
                    }}
                    color="primary"
                  />
                }
                label="Call request sounds"
              />
            </div>
          </div>
          <SimulatorNotesOverview simulators={allSimulators} selectCallback={selectCallback}/>
          <CalendarOverview simulators={allSimulators} selectCallback={selectCallback}/>
        </div>
        {simulatorError && <div className="error">{simulatorError}</div>} {doorError && <div className="error">{doorError}</div>}
        <DevicesList simulators={allSimulators} doors={allDoors} showAll={showAll} selectCallback={selectCallback}
                     selection={selection}/>

        <div className="top-margin">
          {selectedDevice}
        </div>

        <SimulatorAlertBeeper enabled={alertSoundsEnabled} simulators={allSimulators}/>
        <SimulatorCallRequestBeeper enabled={callSoundsEnabled} simulators={allSimulators}/>
        <DoorAlertBeeper enabled={alertSoundsEnabled} doors={allDoors}/>
      </div>
  )
}

export default DashboardMain;
