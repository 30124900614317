import React, {useMemo, useRef, useState} from "react";
import {Bar} from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from "moment-timezone";
import Popover from "@material-ui/core/Popover";
import {useQuery} from "react-query";
import api from "../apiClient";
import DatePicker, {registerLocale, setDefaultLocale} from "react-datepicker";
import localeFi from "date-fns/locale/fi";
import {CircularProgress} from "@material-ui/core";

const options = {
  legend: {display: false},
  scales: {
    yAxes: [{
      ticks: {
        callback: value => value % 1 === 0 ? Math.round(value) : '',
        beginAtZero: true,
      },
      gridLines: {display: false}
    }],
    xAxes: [{
      gridLines: {display: false}
    }]
  },
  tooltips: {enabled: false},
  // responsive: true, // TODO Check these
  maintainAspectRatio: false,
}

const CalendarOverview = ({simulators, selectCallback}) => {
  const [date, setDate] = useState(new Date());
  registerLocale('fi', localeFi);
  setDefaultLocale('fi');

  const from = moment(date).format('YYYY-MM-DD');
  const to = moment(date).format('YYYY-MM-DD');

  const calendarQuery = useQuery(['calendar', from, to], async () => {
    const calendarData = await api.getCalendar(null, from, to);
    if (calendarData.error) {
      throw new Error();
    }
    return calendarData.data.events;
  })

  const anchor = useRef(null);
  const [popoverData, setPopoverData] = useState(null);

  const calendarEvents = calendarQuery.data;

  const eventsByHour = useMemo(() => {
    if (!calendarEvents) {
      return null;
    }
    const eventsByHour = {};
    calendarEvents.forEach(event => {
      const startHour = moment(event.startTime).hour();
      if (!eventsByHour[startHour]) {
        eventsByHour[startHour] = [];
      }
      eventsByHour[startHour].push(event);
    })
    return eventsByHour;
  }, [calendarEvents])

  const chartData = useMemo(() => {
    if (!eventsByHour) {
      return null;
    }
    return {
      labels: Object.keys(eventsByHour),
      datasets: [{
        backgroundColor: '#ed6c05',
        data: Object.values(eventsByHour).map(events => events.length),
        datalabels: {
          color: '#FFFFFF',
          anchor: 'start',
          align: 'start',
          offset: -25
        }
      }]
    }
  }, [eventsByHour]);

  const locationSort = ((a, b) => {
    if (!a.status) {
      return -1;
    }
    if (!b.status) {
      return 1;
    }
    const locationA = a.status.locationName || '';
    const locationB = b.status.locationName || '';
    return locationA.localeCompare(locationB);
  });

  const clickHandler = elements => {
    if (!elements.length) {
      return;
    }
    const element = elements[0];
    const index = element._index;
    const events = Object.values(eventsByHour)[index];
    let eventSimulators = [];
    let eventsMissingSimulator = [];

    // Find a simulator for each event
    events.forEach(event => {
      const eventSimulator = simulators.find(simulator => {
        const status = simulator.status;
        if (!status) {
          return false;
        }
        const lesson = status.lesson;
        if (status.calendarIds.includes(event.locationId)) {
          return true;
        } else if (lesson && lesson.calendarId && lesson.calendarId === event.locationId) {
          return true;
        }
        return false;
      });
      if (eventSimulator) {
        eventSimulators.push(eventSimulator);
      } else {
        eventsMissingSimulator.push(event);
      }
    });
    eventSimulators.sort(locationSort);
    setPopoverData({eventSimulators, eventsMissingSimulator});
  }

  const open = !!popoverData;

  const handleClose = () => {
    setPopoverData(null);
  };

  let content;
  if (calendarQuery.isLoading) {
    content = <div><CircularProgress /></div>;
  } else {
    if (chartData && calendarEvents && calendarEvents.length > 0) {
      content = (
        <Bar
          data={chartData}
          options={options}
          plugins={[ChartDataLabels]}
          onElementsClick={clickHandler}
        />)
    } else {
      content = <div className='calendar-overview'>No calendar events</div>
    }
  }

  return (
    <div className='calendar-overview' ref={anchor}>

      <DatePicker
        selected={date}
        onChange={setDate}
        locale="fi"
        dateFormat="yyy-MM-dd"
        minDate={new Date()}
      />

      {content}

      <Popover
        id="calendar-overview-popover"
        open={open}
        anchorEl={anchor.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {renderPopoverContent(popoverData, selectCallback)}
      </Popover>

    </div>
  )
}

const renderPopoverContent = (popoverData, selectCallback) => {
  if (!popoverData) {
    return null;
  }
  const {eventSimulators : simulators, eventsMissingSimulator} = popoverData;
  let lines = simulators.map(simulator => {
    const deviceId = simulator.deviceId;
    return (
      <div key={deviceId} onClick={() => selectCallback('simulator', deviceId)} className="pointer note">
        {simulator.status.locationName}
      </div>
    );
  });

  lines = lines.concat(eventsMissingSimulator.map(event => <div key={event.eventId}>Unknown calendarId: {event.locationId}</div>));

  return (
    <div className="locations-popover">
      {lines}
    </div>
  )
}

export default CalendarOverview;
