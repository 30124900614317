import React, {useState, useEffect} from 'react';
import api from "../apiClient";
import Button from "@material-ui/core/Button";
import moment from 'moment-timezone';
import CircularProgress from "@material-ui/core/CircularProgress";
import CallIcon from "@material-ui/icons/Call";

const SimulatorCallRequest = ({deviceId, className, callRequest}) => {

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(false);
  }, [callRequest]);

  const removeCallRequest = async (deviceId) => {
    setIsLoading(true);
    await api.removeCallRequest(deviceId)
  }

  if (isLoading) {
    return (
        <div>
          <CircularProgress color="secondary"/>
        </div>
    )
  }

  const renderTime = (time) => {
    const timeMoment = moment(time);
    return (
        <span>{timeMoment.format('dd D.M.')} {timeMoment.format('HH:mm:ss')}</span>
    );
  }

  const renderCallAction = () => {
    return (
        <div>
          <div>{callRequest.callMethod}: {callRequest.callerId}</div>
          <div>{renderTime(callRequest.createdAt)}</div>
        </div>
    );
  }

  const renderRequesting = () => {
    return (
        <div>
          <div className="bold">Student is requesting a call!</div>
          <div className="animate__animated animate__flash animate__infinite">
            <CallIcon className="device-problem-icon"/>
          </div>
          {renderCallAction()}
          <Button variant="contained" color="primary" onClick={() => removeCallRequest(deviceId)}>Silence</Button>
        </div>
    );
  }

  const renderLastRequest = () => {
    return (
        <div>
          <div className="bold">Latest call request</div>
          {renderCallAction()}
        </div>
    );
  }

  const renderNoRequest = () => {
    return (
        <div>
          No call requests
        </div>
    );
  }

  let content;
  if (callRequest && callRequest.state === 'requesting') {
    content = renderRequesting();
  } else if (callRequest) {
    content = renderLastRequest();
  } else {
    content = renderNoRequest();
  }

  return (
      <div className={className}>
        {content}
      </div>
  );
}

export default SimulatorCallRequest;
