import React, {useState} from 'react';
import api from "../apiClient";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

const DoorGetLogs = ({deviceId, className}) => {

  const [isOpen, setIsOpen] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const [error, setError] = useState(null);
  const [logs, setLogs] = useState(null);

  const getLogs = async (deviceId) => {
    setIsRequesting(true);
    setError(null);
    setLogs(null);

    const resp = await api.getDoorLogs(deviceId)
    setIsRequesting(false);
    setError(null);
    if (resp.error) {
      setError(resp.error);
    } else {
      setLogs(resp.logs);
    }
  }

  const renderLogs = (logDataArr) => {
    if (!logDataArr || logDataArr.length === 0) {
      return (<div>Nothing logged</div>);
    }
    return logDataArr.map(logObj => {
      const classes = [];
      switch (logObj.logLevel) {
        case 'error':
        case 'warning':
          classes.push('bold');
          break;
        default:
      }
      return (
          <div key={logObj._id} className={classes.join(' ')}>
            {logObj.logLevel ? logObj.logLevel.toUpperCase() : ''}: {logObj.logMessage}
          </div>
      );
    });
  }

  if (!isOpen) {
    return (
        <div className={className}>
          <Button variant="contained" onClick={() => {
            setIsOpen(true);
            getLogs(deviceId);
          }}>
            Recent Logs
          </Button>
        </div>
    )
  }

  let content;
  if (isRequesting) {
    content = <CircularProgress/>
  } else {
    content = renderLogs(logs)
  }

  return (
      <div className={className}>
        <div className="bold">Recent Logs</div>
        <div className="top-margin">
          {content}
          {error ? <div>{error}</div> : null}
        </div>
      </div>
  );
}

export default DoorGetLogs;
