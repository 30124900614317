import moment from "moment-timezone";

const WARNING_TIMEOUT_S = 300; // 5min

const hasRecentWarning = ({info, createdAt}) => {
  if (!info) {
    return false;
  }
  const hasWarning = info.includes('mopokoodi');
  return hasWarning && moment().subtract(WARNING_TIMEOUT_S, 's').isBefore(createdAt);
}

export {hasRecentWarning}