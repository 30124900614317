import React from 'react';
import './App.css';
import DashboardMain from "./components/DashboardMain";
import ProfileDataProvider from "./components/ProfileDataProvider";
import {QueryClient, QueryClientProvider} from 'react-query'

const queryClient = new QueryClient()

const App = () => (
  <div className="App">
    <ProfileDataProvider>
      <QueryClientProvider client={queryClient}>
        <DashboardMain/>
      </QueryClientProvider>
    </ProfileDataProvider>
  </div>
);

export default App;
