import React, {useEffect, useState} from 'react';
import moment from 'moment-timezone';
import api from "../apiClient";
import classNames from "classnames";
import Button from '@material-ui/core/Button';
import CalendarEvents from "./CalendarEvents";
import SimulatorNotes from "./SimulatorNotes";
import {hasWarning} from "../utils/simulatorStatusUtil";
import SimulatorAssignee from "./SimulatorAssignee";
import AirlineSeatReclineNormalTwoToneIcon from '@material-ui/icons/AirlineSeatReclineNormalTwoTone';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import CloseIcon from '@material-ui/icons/Close';
import SimulatorCallRequest from "./SimulatorCallRequest";
import SimulatorBypassCode from "./SimulatorBypassCode";

const renderAlerts = alerts => {
  if (!alerts || alerts.length === 0) {
    return null;
  }
  return alerts.map((alert, i) => <div className="simulator-alert" key={i}>{alert}</div>);
}

const renderLesson = lesson => {
  if (!lesson) {
    return null;
  }
  return (
      <div>
        <h4>Last Lesson Event</h4>
        {Object.entries(lesson).map(([key, value], i) => <div key={i}><span
            className="bold">{key}:</span> {String(value)}</div>)}
      </div>
  )
};

const renderCurrentStatus = (status, lessonStart) => {
  if (!status) {
      return <div>Status missing</div>;
  }
  const timeMoment = moment(status.time);
  const lessonStartElem = lessonStart &&
      <div><span className="bold">Lesson start:</span> {moment(lessonStart).format('HH:mm:ss')}</div>;

  return (
      <div className="device-section-container">
        <h4>Current Status</h4>
        <div><span className="bold">Sim:</span> {status.deviceId}</div>
        <div><span className="bold">calendarIds:</span> {String(status.calendarIds)}</div>
        {lessonStartElem}
        <div><span
            className="bold">Status received:</span> {timeMoment.format('dd D.M.')} {timeMoment.format('HH:mm:ss')}
        </div>
        {renderAlerts(status.alerts)}
        <div><span className="bold">Info:</span> {status.info}</div>
        <div><span className="bold">Version:</span> {status.version}</div>
        <hr/>
        {renderLesson(status.lesson)}
      </div>
  )
}

const OnlineStatus = ({simulatorData}) => {
  const [ignored, setIgnored] = useState(false);
  if (!simulatorData.isOffline) {
    return null;
  }
  const removePing = async () => {
    setIgnored(true);
    await api.removeSimulatorPing(simulatorData.deviceId);
  }
  return (
      <div className="h-align-center">
        <WifiOffIcon/>&nbsp;
        <span className='margin-right bold'>Simulator is offline.</span>
        <Button variant="contained" disabled={ignored} color="secondary" onClick={removePing}>
          Ignore
        </Button>
      </div>
  )
}

const renderLessonStatuses = statuses => {
  if (!statuses || statuses.length === 0) {
    return null;
  }
  const statusElems = statuses.map(status => {

      let hasAlert = status ? status.alerts.length > 0 : false;
      let hasWarnings = hasWarning(status);

      // Override alert/warning status if priority is set
      if (status.priority !== undefined) {
          switch (status.priority) {
              case "medium":
                  hasAlert = false;
                  hasWarnings = true;
                  break;

              case "high":
                  hasAlert = true;
                  hasWarnings = false;
                  break;

              default:
          }
      }

    const classes = classNames({'alert': hasAlert, 'warning': hasWarnings});

    return (
        <div key={status._id} className="smaller">
          <span className="margin-right mono-font">{moment(status.time).format('HH:mm:ss')}</span>
          <span className={classes}>{status.lesson && (status.lesson.sectionName + ',')} {status.info}</span>
        </div>
    )
  });
  return (
      <div className="lesson-status-list margin-top">
        <div className="bold">Received statuses:</div>
        {statusElems}
      </div>
  )
}

const Simulator = ({data, clearSelectionCallback}) => {
  const calendarEventId = data.status?.lesson && data.status.lesson.calendarEventId;
  const [statuses, setStatuses] = useState([])
  const [lessonStart, setLessonStart] = useState(null);

  useEffect(() => {
    setStatuses([]);
    setLessonStart(null);
  }, [data.deviceId, calendarEventId])

  useEffect(() => {
    if (calendarEventId) {
      api.getSimulatorStatusesByEventId(data.deviceId, calendarEventId)
          .then(statusList => {
            if (!statusList.error) {
              statusList.sort((a, b) => moment(a.time).diff(b.time));
              setStatuses(statusList);

              if (statusList.length > 0) {
                const firstStatus = statusList[0];
                setLessonStart(firstStatus.time);
              }

            }
          })
    } else {
      api.getSimulatorStatuses(data.deviceId, 50)
          .then(statusList => {
            if (!statusList.error) {
              statusList.sort((a, b) => moment(a.time).diff(b.time));
              setStatuses(statusList);
            }
          })
    }
  }, [data.deviceId, data.updatedAt, calendarEventId])

  const calendarIds = [];
  if (data.status?.calendarIds) {
      calendarIds.push(...data.status.calendarIds);
  }
  const lesson = data.status?.lesson;
  if (lesson && lesson.calendarId && !calendarIds.includes(lesson.calendarId)) {
    calendarIds.push(lesson.calendarId);
  }

  return (
      <div className="gray-container">
        <div className="h-align-sides">
          <div className="h-align-center larger">
            <AirlineSeatReclineNormalTwoToneIcon
                onClick={() => clearSelectionCallback()}/> {data.status?.locationName}
          </div>
          <Button onClick={() => clearSelectionCallback()}>
            <CloseIcon/>
          </Button>
        </div>

        <div className="device-container">
          <div className="device-tools-container">
            <SimulatorAssignee deviceId={data.deviceId} assignee={data.assignee} className="device-section-container"/>
            <SimulatorCallRequest callRequest={data.callRequest} deviceId={data.deviceId} className="device-section-container" />
            <SimulatorNotes simulator={data} className="device-section-container"/>
            <CalendarEvents calendarIds={calendarIds}/>
            <SimulatorBypassCode className="device-section-container"/>
          </div>
          <div className="device-status-container">
            <OnlineStatus simulatorData={data} key={data.deviceId}/>
            {renderCurrentStatus(data.status, lessonStart)}
            {renderLessonStatuses(statuses)}
          </div>
        </div>
      </div>
  );
}

export default Simulator;
