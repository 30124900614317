import React, {useState} from 'react';
import moment from 'moment-timezone';
import api from "../apiClient";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";

const SimulatorNotes = ({simulator, className}) => {

  const [note, setNote] = useState("");

  const addNote = async () => {
    await api.addNote(simulator.deviceId, note);
    setNote('');
  }

  const hideNote = noteId => async () => {
    await api.hideNote(simulator.deviceId, noteId)
  }

  let notes = simulator.notes.filter(note => note.visible).map(note => {
    return (
      <div key={note._id} className="v-align-center">
        {moment(note.date).format('DD.MM.YYYY')} - {note.body}
        <IconButton onClick={hideNote(note._id)}>
          <DeleteIcon/>
        </IconButton>
      </div>
    )
  });

  return (
    <div className={className}>
      <div className="bold">Notes</div>
      {notes}
      <div className="v-align-center margin-top">
        <TextField id="outlined-basic" variant="outlined" size="small" value={note}
                   onChange={e => setNote(e.target.value)}/>

        <IconButton aria-label="add" disabled={note.length < 3} onClick={addNote}>
          <AddCircleIcon/>
        </IconButton>

      </div>
    </div>
  );
}

export default SimulatorNotes;
