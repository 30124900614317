import React, {useState} from 'react';
import Button from "@material-ui/core/Button";
import moment from 'moment-timezone';

const SimulatorBypassCode = ({className}) => {

    const [codeVisible, setCodeVisible] = useState(false);

    let codeSpan;
    let buttonLabel;
    if (codeVisible) {
        const now = moment();
        const seed = parseInt(now.format('MMDD')) * 33;
        const code = "007" + seed.toString().slice(-3);
        codeSpan = <span className="margin-left">{code}</span>;
        buttonLabel = "Hide";
    } else {
        buttonLabel =  "Show";
    }

    return (
        <div className={className}>
            <span className="margin-right">
                Time limit bypass code:
            </span>
            <Button variant="contained"
                    color="primary"
                    onClick={() => setCodeVisible(!codeVisible)}>
                {buttonLabel}
            </Button>
            <span className="margin-left">
            {codeVisible && codeSpan}
            </span>
        </div>
    );
}

export default SimulatorBypassCode;
