import axios from 'axios';
import cache from 'memory-cache';
import qs from 'qs';

const API_VERSION = '/api/v1';
const BASE_URL = '' + API_VERSION;

const PROFILE_URL = BASE_URL + '/profile';
const SIMULATORS_URL = BASE_URL + '/simulators';
const SIMULATOR_STATUS_URL = BASE_URL + '/simulators/:deviceId/status';
const CALENDAR_URL = BASE_URL + '/calendar';
const SIMULATOR_NOTES_URL = BASE_URL + '/simulators/:deviceId/notes';
const SIMULATOR_PING_URL = BASE_URL + '/simulators/:simulatorId/ping';
const SIMULATOR_SELF_ASSIGN_URL = BASE_URL + '/simulators/:deviceId/assignee';
const SIMULATOR_CALL_REQUEST = BASE_URL + '/simulators/:deviceId/call-request';
const DOOR_ADD_PIN_CODE_URL = BASE_URL + '/doors/:deviceId/commands/add-pin-code';
const DOOR_GET_PIN_CODES_URL = BASE_URL + '/doors/:deviceId/commands/manual-pin-codes';
const DOOR_GET_LOGS = BASE_URL + '/doors/:deviceId/log-entries';
const DOORS_URL = BASE_URL + '/doors';
const DOOR_UNLOCK_URL = BASE_URL + '/doors/:doorId/commands/unlock';
const DOOR_CONTROLLER_RESTART_URL = BASE_URL + '/doors/:doorId/commands/restart-controller';
const DOOR_SIMULATORS_START_URL = BASE_URL + '/doors/:doorId/commands/start-simulators';

const REGULAR_TIMEOUT_MS = 20000;

// Cache time in seconds
const CACHE_TIME_INFINITY = 0;
const CACHE_TIME_NEVER = -1;
const CACHE_TIME_VERY_SHORT = 3;
const CACHE_TIME_SHORT = 60;
// const CACHE_TIME_LONG = 60 * 15;

class ApiClient {

  /**
   * Clear memory cache
   */

  cacheClear() {
    cache.clear();
  }

  getProfile() {
    return this.get(PROFILE_URL, REGULAR_TIMEOUT_MS, CACHE_TIME_SHORT);
  }

  getSimulators() {
    return this.get(SIMULATORS_URL, REGULAR_TIMEOUT_MS, CACHE_TIME_VERY_SHORT);
  }

  getSimulatorStatuses(simulatorId, count) {
    let url = SIMULATOR_STATUS_URL.replace(':deviceId', simulatorId)
    if (count) {
      url += '?count=' + count;
    }
    return this.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_VERY_SHORT);
  }

  getSimulatorStatusesByEventId(simulatorId, calendarEventId) {
    let url = SIMULATOR_STATUS_URL.replace(':deviceId', simulatorId);
    url += '?calendarEventId=' + calendarEventId;
    return this.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_VERY_SHORT);
  }

  getCalendar(calendarId, from, to) {
    let url = CALENDAR_URL;
    const params = {
      calendarid: calendarId || undefined,
      from: from || undefined,
      to: to || undefined
    }
    url += qs.stringify(params, { addQueryPrefix: true });
    return this.get(url, REGULAR_TIMEOUT_MS, CACHE_TIME_SHORT);
  }

  addNote(deviceId, note) {
    const url = SIMULATOR_NOTES_URL.replace(':deviceId', deviceId);
    return this.post(url, {body: note}, REGULAR_TIMEOUT_MS);
  }

  hideNote(deviceId, noteId) {
    let url = SIMULATOR_NOTES_URL.replace(':deviceId', deviceId);
    url += '/' + noteId;
    this.post(url, null, REGULAR_TIMEOUT_MS);
  }

  addSimulatorAssignee(deviceId) {
    const url = SIMULATOR_SELF_ASSIGN_URL.replace(':deviceId', deviceId);
    return this.post(url, null, REGULAR_TIMEOUT_MS);
  }

  addDoorPin(deviceId, validFrom, validUntil, notes) {
    const url = DOOR_ADD_PIN_CODE_URL.replace(':deviceId', deviceId);
    return this.post(url, {validFrom, validUntil, notes}, REGULAR_TIMEOUT_MS);
  }

  getManualPinCodes(deviceId) {
    return this.get(DOOR_GET_PIN_CODES_URL.replace(':deviceId', deviceId), REGULAR_TIMEOUT_MS, CACHE_TIME_NEVER);
  }

  getDoorLogs(deviceId) {
    return this.get(DOOR_GET_LOGS.replace(':deviceId', deviceId), REGULAR_TIMEOUT_MS, CACHE_TIME_VERY_SHORT);
  }

  removeSimulatorAssignee(deviceId) {
    const url = SIMULATOR_SELF_ASSIGN_URL.replace(':deviceId', deviceId);
    return this.delete(url, REGULAR_TIMEOUT_MS);
  }

  removeCallRequest(deviceId) {
    const url = SIMULATOR_CALL_REQUEST.replace(':deviceId', deviceId);
    return this.delete(url, REGULAR_TIMEOUT_MS);
  }

  removeSimulatorPing(simulatorId) {
    let url = SIMULATOR_PING_URL.replace(':simulatorId', simulatorId)
    return this.delete(url, REGULAR_TIMEOUT_MS);
  }

  getDoors() {
    return this.get(DOORS_URL, REGULAR_TIMEOUT_MS, CACHE_TIME_VERY_SHORT);
  }

  unlockDoor(doorId) {
    let url = DOOR_UNLOCK_URL.replace(':doorId', doorId);
    return this.post(url, null, REGULAR_TIMEOUT_MS);
  }

  restartDoorController(doorId) {
    let url = DOOR_CONTROLLER_RESTART_URL.replace(':doorId', doorId);
    return this.post(url, null, REGULAR_TIMEOUT_MS);
  }

  startDoorControlledSimulators(doorId) {
    let url = DOOR_SIMULATORS_START_URL.replace(':doorId', doorId);
    return this.post(url, null, REGULAR_TIMEOUT_MS);
  }

  // ----------------------------

  get(url, timeout, cacheTimeSec, forceReload = false) {
    return this.apiCall(url, 'get', null, cacheTimeSec, timeout, forceReload);
  }

  post(url, data, timeout) {
    return this.apiCall(url, 'post', data, CACHE_TIME_NEVER, timeout, true)
  }

  delete(url, timeout) {
    return this.apiCall(url, 'delete', null, CACHE_TIME_NEVER, timeout, true);
  }

  async apiCall(url, method, data, cacheTimeSec, timeout, forceReload) {
    if (!forceReload) {
      const cachedResponse = cache.get(method + url);
      if (cachedResponse) {
        return new Promise((resolve) => {
          resolve(cachedResponse);
        });
      }
    }

    const callParams =
      {
        method: method,
        url: url,
        data: data,
        timeout: timeout
      };
    let response;
    try {
      response = await axios(callParams)
    } catch (e) {
      const httpStatus = e.response.status;
      if (httpStatus === 401) {
        window.location.href = '/login';
        return {error: true};
      }

      // TODO
      cache.clear();
      return {error: 'HTTP status ' + httpStatus + ' Error: ' + e.response.data};
    }

    const responseData = response.data;
    if (cacheTimeSec !== CACHE_TIME_NEVER) {
      await cache.put(method + url, responseData, cacheTimeSec === CACHE_TIME_INFINITY ? undefined : cacheTimeSec * 1000);
    }
    return responseData;
  }

}

const api = new ApiClient();

export default api;
