import React, {useContext} from 'react';
import {ProfileContext} from './ProfileDataProvider';
import ExitToApp from "@material-ui/icons/ExitToApp";

const Profile = () => {
  const profile = useContext(ProfileContext);

  if (!profile) {
    return null;
  }

  return (
    <div className="align-left profile top-margin" onClick={() => window.location.href = '/logout'}>
      <span className="margin-right">{profile.name}</span><ExitToApp />
    </div>
  );
}

export default Profile;
