import React from 'react';

import classNames from 'classnames';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import moment from 'moment-timezone';
import {hasRecentWarning} from "../utils/doorStatusUtil";

const renderAlerts = (alerts) => {
  if (!alerts || alerts.length === 0) {
    return null;
  }
  const renderedAlerts = alerts.map((alert, i) => {
    return (<div className="simulator-alert" key={i}>{alert}</div>)
  });
  return (<div>{renderedAlerts}</div>);
}

const DoorListItem = ({doorData, selectCallback, isSelected, isMinified}) => {
  const {status} = doorData;
  const isUnlocked = status.lock === 'unlocked';
  const isAway = status.awaySwitch === 'away';
  const alerts = (status && status.alerts) || [];
  const hasAlert = alerts.length > 0 || (isUnlocked && isAway);
  const hasWarnings = hasRecentWarning(status);
  const classes = classNames('simulator-list-item', 'door', {
    'alert': hasAlert,
    'warning' : hasWarnings,
    'selected': isSelected,
    'minified': isMinified
  })


  let alertDurationElem = null;
  if (hasAlert) {
    const diff = moment().diff(status.time);
    alertDurationElem = <div>{moment.duration(diff).format()}</div>;
  }


  return (
      <div className={classes} onClick={selectCallback}>
        <div>{status ? status.locationName : null}</div>
        {status ? renderAlerts(status.alerts) : null}
        {alertDurationElem}
        {doorData.isOffline && <WifiOffIcon className="device-problem-icon"/>}
        {doorData.status.lock === 'unlocked' && <LockOpenOutlinedIcon />}
      </div>
  );
}

export default DoorListItem;
