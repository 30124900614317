import {useEffect, useState} from "react";
import beep from '../assets/electric-sweep.mp3';
import useSound from "use-sound";

const SimulatorCallRequestBeeper = props => {
  const {enabled, simulators} = props;
  const [playBeep] = useSound(beep);
  const [alertingIds, setAlertingIds] = useState([]);

  useEffect(() => {
    const isAlertingFilter = (sim) => {
      return sim.callRequest && sim.callRequest.state === 'requesting';
    }
    const currentAlertingIds = simulators.filter(isAlertingFilter).map(sim => sim.deviceId);
    const hasNewAlerts = currentAlertingIds.filter(id => !alertingIds.includes(id)).length > 0;

    if(alertingIds.length !== currentAlertingIds.length || hasNewAlerts) {
      setAlertingIds(currentAlertingIds);
    }

    if (enabled && hasNewAlerts) {
      playBeep();
    }
  }, [simulators, alertingIds, enabled, playBeep])

  return null;
}

export default SimulatorCallRequestBeeper;
