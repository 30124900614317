import React, {useEffect, useState} from "react";
import api from "../apiClient";

export const ProfileContext = React.createContext(null);

const ProfileDataProvider = props => {
  const [profile, setProfile] = useState(null);

  const getProfile = async () => {
    const profile = await api.getProfile()
    if (!profile.error) {
      setProfile(profile);
    }
  }

  useEffect(() => {
    getProfile();
  }, [])

  return (
    <ProfileContext.Provider value={profile}>
      {props.children}
    </ProfileContext.Provider>
  )
}

export default ProfileDataProvider;
