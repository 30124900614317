import React from 'react';

const SimulatorNotesOverview = ({simulators, selectCallback}) => {

  const notes = [];
  simulators.forEach(simulator => {
    const visibleNotes = simulator.notes.filter(note => note.visible);
    if(!visibleNotes.length) {
      return;
    }
    const bodies = visibleNotes.map(note => note.body);
    notes.push((
      <div key={simulator.deviceId} onClick={() => selectCallback('simulator', simulator.deviceId)} className="note pointer">
        <strong>{simulator.status.locationName}:</strong> {bodies.join(', ')}
      </div>
    ))
  })

  return (
    <div className="current-notes align-left">
      <div className="bold">Current notes:</div>
      {notes}
    </div>
  );
}

export default SimulatorNotesOverview;
