import React, {useState} from 'react';
import api from "../apiClient";
import Button from "@material-ui/core/Button";
import WarningTwoToneIcon from "@material-ui/icons/WarningTwoTone";

const DoorControllerRestart = ({deviceId, className}) => {

  const [isRestartButtonVisible, setRestartButtonVisible] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);

  const sendResetCommand = async (deviceId) => {
    setIsRequesting(true);
    setResult(null);
    const resp = await api.restartDoorController(deviceId);
    setError(null);
    if (resp.error) {
      setError(resp.error);
    } else {
      setResult('Controller restart requested...');
    }
    setIsRequesting(false);
  }

  if (!isRestartButtonVisible) {
    return (
        <div className={className}>
          <Button variant="contained" onClick={() => setRestartButtonVisible(true)}>Restart door controller</Button>
        </div>
    )
  }

  return (
      <div className={className}>
        <div>Warning, this will restart the door controller. Door will be unresponsive during restart!</div>
        <Button variant="contained" color="secondary" onClick={() => sendResetCommand(deviceId)} disabled={isRequesting || result}>
          <WarningTwoToneIcon/> <strong>Restart</strong> <WarningTwoToneIcon/>
        </Button>
        {error ? <div>{error}</div> : null}
        {result ? <div>{result}</div> : null}
      </div>
  );
}

export default DoorControllerRestart;
