import React, {useState} from 'react';
import api from "../apiClient";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from 'moment-timezone';
import DoorAddPin from "./DoorAddPin";

const DoorGetManualPinCodes = ({deviceId, className}) => {

  const [isOpen, setIsOpen] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const [error, setError] = useState(null);
  const [pins, setPins] = useState(null);

  const getPinCodes = async (deviceId) => {
    setIsRequesting(true);
    setError(null);
    setPins(null);

    const resp = await api.getManualPinCodes(deviceId)
    setIsRequesting(false);
    setError(null);
    if (resp.error) {
      setError(resp.error);
    } else {
      setPins(resp.pinCodes);
    }
  }

  const renderPins = (pinCodeDataArr) => {
    if (!pinCodeDataArr || pinCodeDataArr.length === 0) {
      return (<div>No PIN codes</div>);
    }
    const dayInMs = 1000 * 60 * 60 * 24;
    const FORMAT_FULL = 'YYYY-MM-DD HH:mm';
    const FORMAT_DATE = 'YYYY-MM-DD';
    const FORMAT_TIME = 'HH:mm';

    return pinCodeDataArr.map(pinObj => {
      const from = moment(pinObj.data.validFrom);
      const until = moment(pinObj.data.validUntil);
      const timeDiffMs = until.diff(from);

      const timespan = timeDiffMs > dayInMs
          ? <span><strong>{from.format(FORMAT_FULL)} - {until.format(FORMAT_FULL)}</strong></span>
          : <span><strong>{from.format(FORMAT_DATE)}</strong> {from.format(FORMAT_TIME)} - {until.format(FORMAT_TIME)}</span>

      if (!pinObj.ack) {
        return (
            <div key={pinObj._id} className="top-margin blended">
              Not yet stored to door...<br />
              {timespan}<br/>
              PIN: {pinObj.data.pinCode} "{pinObj.data.notes}"
            </div>
        );
      } else {
        return (
            <div key={pinObj._id} className="top-margin">
              {timespan}<br/>
              PIN: {pinObj.data.pinCode}&nbsp;&nbsp;"{pinObj.data.notes}"
            </div>
        );
      }
    });
  }

  if (!isOpen) {
    return (
        <div className={className}>
          <Button variant="contained" onClick={() => {
            setIsOpen(true);
            getPinCodes(deviceId);
          }}>
            Manual PIN Codes
          </Button>
        </div>
    )
  }

  let content;
  if (isRequesting) {
    content = <CircularProgress/>
  } else {
    content = renderPins(pins)
  }

  return (
      <div className={className}>
        <div className="bold">Manually added PIN codes</div>
        <DoorAddPin deviceId={deviceId} className="top-margin" callback={() => getPinCodes(deviceId)}/>
        <div className="top-margin">
          {content}
          {error ? <div>{error}</div> : null}
        </div>
      </div>
  );
}

export default DoorGetManualPinCodes;
