import React, {useContext, useRef} from 'react';
import moment from 'moment-timezone';
// import localizations from '../localizations/localizations';
import classNames from 'classnames';
import {Line} from 'rc-progress';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import ReportIcon from '@material-ui/icons/Report';
import CallIcon from '@material-ui/icons/Call';
import GroupIcon from '@material-ui/icons/Group';
import StarIcon from '@material-ui/icons/Star';
import {hasWarning} from "../utils/simulatorStatusUtil";
import {ProfileContext} from "./ProfileDataProvider";

const DEFAULT_LESSON_LENGTH_S = 3000; // 50 min
const WARNING_TIMEOUT_S = 300; // 5min

const renderLesson = (lesson, isActive) => {
  if (!isActive) {
    return null;
  }
  const seconds = lesson ? lesson.secondsSinceLessonStart : 0;
  const progress = Math.min(100, seconds / DEFAULT_LESSON_LENGTH_S * 100);
  return (
      <div className="simulator-list-item-progress-container">
        {/*{localizations.name}: {lesson.studentName}<br />*/}
        {/*{localizations.calendarId}: {lesson.calendarEventId}*/}
        <Line percent={progress} strokeWidth={10} trailWidth={10} strokeColor="#00000055" trailColor="#00000011"/>
      </div>
  )
};

const renderAlerts = (alerts) => {
  if (!alerts || alerts.length === 0) {
    return null;
  }
  const renderedAlerts = alerts.map((alert, i) => {
    return (<div className="simulator-alert" key={i}>{alert}</div>)
  });
  return (<div>{renderedAlerts}</div>);
}

const SimulatorListItem = ({simulatorData, selectCallback, isSelected, isMinified}) => {
  const seenStatusIdRef = useRef(null);
  const {status, callRequest} = simulatorData;

  const profile = useContext(ProfileContext);
  const assignedToMe = profile && simulatorData.assignee === profile.name;

  // TODO: Quick fix, fix properly and alert to somewhere!
  if (!status) {
    return (
      <div className={'simulator-list-item warning'} key={simulatorData.deviceId}>
        <div>
          Missing status in data!
        </div>
        <div>
          {simulatorData.deviceId}
        </div>
      </div>
    );
  }

  if (isSelected) {
    seenStatusIdRef.current = status._id;
  }

  const isRequestingCall = callRequest && callRequest.state === 'requesting';
  let hasAlert = status.alerts.length > 0;
  let hasWarnings = hasWarning(status);

  // Override alert/warning status if priority is set
  if (status.priority !== undefined) {
    switch (status.priority) {
      case "medium":
        hasAlert = false;
        hasWarnings = true;
        break;

      case "high":
        hasAlert = true;
        hasWarnings = false;
        break;

      default:
    }
  }

  hasWarnings = hasWarnings && moment().subtract(WARNING_TIMEOUT_S, 's').isBefore(status.createdAt);

  const isActive = simulatorData.isActive;
  const classes = classNames('simulator-list-item', {
    'requesting-call': isRequestingCall,
    'alert': hasAlert,
    'warning': hasWarnings,
    'active': isActive,
    'selected': isSelected,
    'minified': isMinified
  });

  let durationElem = null;
  if (hasWarnings || hasAlert) {
    const diff = moment().diff(status.createdAt);
    durationElem = <div className="small-text">{moment.duration(diff).format()}</div>;
  }

  const showIcon = (hasAlert || hasWarnings || isRequestingCall) && (!simulatorData.assignee || (assignedToMe && status._id !== seenStatusIdRef.current));
  let iconElem;
  if (showIcon) {
    if (isRequestingCall) {
      iconElem = (
          <div className="animate__animated animate__flash animate__infinite">
            <CallIcon className="device-problem-icon"/>
          </div>);

    } else {
      iconElem = (
          <div className="animate__animated animate__heartBeat animate__infinite">
            <ReportIcon className="device-problem-icon"/>
          </div>);
    }
  }

  const isHandling = status.lesson && (status.lesson.lessonName === "Lesson_L1" || status.lesson.lessonName === "ka_opetus");

  return (
      <div className={classes} onClick={selectCallback} id={simulatorData.deviceId}>
        {status?.version ? <StarIcon className="new-sim-icon" /> : null}
        {status ? status.locationName : null}
        {status ? renderAlerts(status.alerts) : null}
        {durationElem}
        {iconElem}
        {simulatorData.assignee && <div className="small-text">({simulatorData.assignee})</div>}
        <div>
          {simulatorData.isOffline && <WifiOffIcon className="device-problem-icon"/>}
          {isHandling && <GroupIcon className="device-problem-icon"/>}
        </div>
        {!showIcon && renderLesson(status.lesson, isActive)}
      </div>
  );
}

export default SimulatorListItem;
