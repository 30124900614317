import React, {useState} from 'react';
import api from "../apiClient";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from '@material-ui/core/TextField';
import localeFi from 'date-fns/locale/fi';
import DatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const DoorAddPin = ({deviceId, className, callback}) => {

  const [isOpen, setIsOpen] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const [error, setError] = useState(null);
  const [validFrom, setValidFrom] = useState(new Date()); //useState('2021-04-21 21:21:00');
  const [validUntil, setValidUntil] = useState(null); //useState('2021-04-21 22:21:00');
  const [notes, setNotes] = useState(null);
  const [createdPin, setCreatedPin] = useState(null);

  registerLocale('fi', localeFi);
  setDefaultLocale('fi');

  if (!isOpen) {
    return (
        <div className={className}>
          <Button variant="contained" onClick={() => setIsOpen(true)}>Add PIN Code</Button>
        </div>
    )
  }

  const addPin = async (deviceId) => {
    setIsRequesting(true);
    setError(null);
    setCreatedPin(null);

    const resp = await api.addDoorPin(deviceId, validFrom, validUntil, notes)
    setIsRequesting(false);
    setError(null);
    if (resp.error) {
      setError(resp.error);
      setCreatedPin(null);
    } else {
      setCreatedPin(resp.pinCode);
      callback && callback();
    }
  }

  const inputContent = (
      <div>
        <form noValidate>
          Valid between<br/>
          <DatePicker
              selected={validFrom}
              onChange={setValidFrom}
              locale="fi"
              showTimeSelect
              timeFormat="p"
              timeIntervals={15}
              dateFormat="yyy-MM-dd HH:mm"
              minDate={new Date()}
            />
         -
          <DatePicker
              selected={validUntil || validFrom}
              onChange={setValidUntil}
              locale="fi"
              showTimeSelect
              timeFormat="p"
              timeIntervals={15}
              dateFormat="yyy-MM-dd HH:mm"
              minDate={new Date()}
          />
          <TextField
            id="notes"
            label="Notes"
            className="full-width"
            onChange={(e) => setNotes(e.target.value)}
           />
        </form>
        <br />
        <div className="warning">
          {error}
        </div>
        <Button onClick={() => addPin(deviceId)} variant="contained" className="top-margin">Add</Button>
      </div>
  );

  return (
      <div className={className}>
        <div className="bold">Add PIN</div>
        <div className="top-margin">{isRequesting ? <CircularProgress/> : inputContent}</div>
        <div>
          {createdPin
              ? <div className="top-margin">Created PIN <strong>{createdPin}</strong></div>
              : null}
        </div>
      </div>
  );
}

export default DoorAddPin;
