import {useEffect, useState} from "react";
import beep from '../assets/horn.mp3';
import useSound from "use-sound";

const DoorAlertBeeper = props => {
  const {enabled, doors} = props;
  const [playBeep] = useSound(beep);
  const [alertingIds, setAlertingIds] = useState([]);

  useEffect(() => {
    const isAlertingFilter = (door) => {
      return door.status.alerts.length > 0;
    }
    const currentAlertingIds = doors.filter(isAlertingFilter).map(door => door.deviceId);
    const hasNewAlerts = currentAlertingIds.filter(id => !alertingIds.includes(id)).length > 0;

    if(alertingIds.length !== currentAlertingIds.length || hasNewAlerts) {
      setAlertingIds(currentAlertingIds);
    }

    if (enabled && hasNewAlerts) {
      playBeep();
    }
  }, [doors, alertingIds, enabled, playBeep])

  return null;
}

export default DoorAlertBeeper;
