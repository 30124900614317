import {useEffect, useState} from "react";
import beep from '../assets/beep-warning.mp3';
import useSound from "use-sound";

const SimulatorAlertBeeper = props => {
  const {enabled, simulators} = props;
  const [playBeep] = useSound(beep);
  const [alertingIds, setAlertingIds] = useState([]);

  useEffect(() => {
    const isAlertingFilter = (sim) => {
      if (!sim.status || !sim.status.alerts) {
        return false;
      }
      return sim.status.alerts.length > 0;
    }

    const currentAlertingIds = simulators.filter(isAlertingFilter).map(sim => sim.deviceId);
    const hasNewAlerts = currentAlertingIds.filter(id => !alertingIds.includes(id)).length > 0;

    if(alertingIds.length !== currentAlertingIds.length || hasNewAlerts) {
      setAlertingIds(currentAlertingIds);
    }

    if (enabled && hasNewAlerts) {
      playBeep();
    }
  }, [simulators, alertingIds, enabled, playBeep])

  return null;
}

export default SimulatorAlertBeeper;
