import React from 'react';
import SimulatorListItem from './SimulatorListItem';
import DoorListItem from "./DoorListItem";
import {hasRecentWarning} from "../utils/doorStatusUtil";

const DevicesList = ({simulators, doors, showAll, selectCallback, selection}) => {
  if (!simulators && !doors) {
    return null;
  }

  // Pick simulators that are falsely configured to have overlapping ids.
  const duplicateSimulatorIds = simulators.map(simulator => simulator.deviceId).filter((deviceId, index, self) => self.indexOf(deviceId) !== index);

  // Pick doors that are falsely configured to have overlapping ids.
  const duplicateDoorIds = doors.map(door => door.deviceId).filter((deviceId, index, self) => self.indexOf(deviceId) !== index);

  const visibleSimulators = simulators.filter(simulator => {
    const hasCallRequest = simulator.callRequest?.state === 'requesting';
    return simulator.isActive || simulator.isOffline || hasCallRequest || showAll;
  });

  const visibleDoors = doors.filter(door => {
    const alerts = (door.status && door.status.alerts) || [];
    const hasAlert = alerts.length > 0;
    const hasWarnings = hasRecentWarning(door.status);
    const isUnlocked = door.status.lock === 'unlocked';
    return hasAlert || hasWarnings || door.isOffline || isUnlocked || showAll;
  });

  const selectedSimulatorId = selection && selection.deviceType === 'simulator' && selection.deviceId;
  const selectedDoorId = selection && selection.deviceType === 'door' && selection.deviceId;
  const isMinified = selectedDoorId || selectedSimulatorId;

  const simulatorElements = visibleSimulators.map(simulator => <SimulatorListItem simulatorData={simulator}
                                                                    selectCallback={() => selectCallback('simulator', simulator.deviceId)}
                                                                    isSelected={simulator.deviceId === selectedSimulatorId}
                                                                    isMinified={isMinified}
                                                                    key={simulator._id}/>);

  const doorElements = visibleDoors.map(door => <DoorListItem doorData={door}
                                                              selectCallback={() => selectCallback('door', door.deviceId)}
                                                              isSelected={door.deviceId === selectedDoorId}
                                                              isMinified={isMinified}
                                                              key={door._id} />);

  return (
      <div>
        {duplicateSimulatorIds.length > 0 &&
          <div className={"error"}>
            Simulators with overlapping ids configured: {duplicateSimulatorIds.join(', ')}
          </div>
        }
        {duplicateDoorIds.length > 0 &&
          <div className={"error"}>
            Doors with overlapping ids configured: {duplicateDoorIds.join(', ')}
          </div>
        }
        <div className="device-list">
          {simulatorElements}
        </div>
        <div className="device-list">
          {doorElements}
        </div>
      </div>
  );
}

export default DevicesList;
